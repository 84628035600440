/**
 * angular2-cookie-law
 *
 * Copyright 2016-2018, @andreasonny83, All rights reserved.
 *
 * @author: @andreasonny83 <andreasonny83@gmail.com>
 */

import {
  Component,
  OnInit,
  HostBinding,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AnimationEvent } from '@angular/animations';
import { closeIcon } from './icons';
import { translateInOut } from './animations';
import {
  CookieLawAnimation,
  CookieLawTarget,
  CookieLawPosition
} from './definitions';

@Component({
  selector: 'cookie-law-component',
  template: `<div
  class="cookie-law-wrapper"
  [ngStyle]="currentStyles"
  [@transition]="transition"
  (@transition.done)="afterDismissAnimation($event)"
>
  <div class="copy">
    <span #ref><ng-content></ng-content></span>

    <span *ngIf="ref.childNodes.length == 0">
      By continuing to browse the site, you're agreeing to our use of cookies.
      <span *ngIf="learnMore">
        Learn more in our
        <a [href]="learnMore" [target]="target" [rel]="noopener ? 'noopener noreferrer' : null"
          >privacy policy</a
        >.
      </span>
    </span>

    <a
      href="#"
      role="button"
      class="dismiss"
      [innerHTML]="closeSvg"
      (click)="dismiss($event)"
      [attr.aria-label]="closeSvg"
    ></a>
  </div>
</div>
`,
  styles: [`.cookie-law-wrapper{background:#333;color:#bbb;display:block;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;font-size:15px;font-weight:200;line-height:20px;position:fixed;bottom:0;left:0;width:100%;z-index:999999999;font-smooth:always;-webkit-font-smoothing:antialiased}.cookie-law-wrapper a{color:#bbb;transition:color .2s}.cookie-law-wrapper a:hover{color:#fff}.cookie-law-wrapper a:hover svg{fill:#fff}.cookie-law-wrapper .dismiss{display:block;box-sizing:border-box;padding:10px;position:absolute;top:0;right:10px;text-decoration:none;line-height:20px}.cookie-law-wrapper .dismiss svg{display:block;fill:#bbb;width:20px;height:20px;transition:fill .2s}.cookie-law-wrapper .copy{box-sizing:border-box;padding:10px 60px 10px 10px}.cookie-law-wrapper .copy span{color:#fff;font-weight:400}.cookie-law-wrapper .copy a{text-decoration:underline}.cookie-law-wrapper .copy a:active,.copy a:hover{outline:0}@media (min-width:600px){.cookie-law-wrapper .copy{padding:20px 60px 20px 20px;font-size:18px;line-height:24px}.cookie-law-wrapper .dismiss{top:10px;right:15px}.cookie-law-wrapper .dismiss svg{width:24px;height:24px}}`],
  animations: [translateInOut],
  encapsulation: ViewEncapsulation.None
})
export class CookieLawComponent implements OnInit {
  public closeSvg: SafeHtml;
  public currentStyles: any;
  public transition: CookieLawAnimation;

  @HostBinding('class.cookie-law')
  public cookieLawClass: boolean;

  @Input()
  get learnMore() {
    return this._learnMore;
  }
  set learnMore(value: string) {
    this._learnMore = value !== null && `${value}` !== 'false' ? value : null;
  }

  @Input()
  get awsomeCloseIcon() {
    return this._awsomeCloseIcon;
  }
  set awsomeCloseIcon(value: string) {
    this._awsomeCloseIcon =
      value !== null && `${value}` !== 'false' ? value : null;
  }

  @Input()
  get target() {
    return this._target;
  }
  set target(value: CookieLawTarget) {
    this._target =
      value !== null &&
      `${value}` !== 'false' &&
      (`${value}` === '_blank' || `${value}` === '_self')
        ? value
        : '_blank';
  }

  @Input()
  get position() {
    return this._position;
  }
  set position(value: CookieLawPosition) {
    this._position =
      value !== null &&
      `${value}` !== 'false' &&
      (`${value}` === 'top' || `${value}` === 'bottom')
        ? value
        : 'bottom';
  }

  @Output()
  public isSeen = new EventEmitter<boolean>();

  public noopener: boolean;

  private _learnMore: string;
  private _awsomeCloseIcon: string;
  private _target: CookieLawTarget;
  private _position: CookieLawPosition;

  constructor(private domSanitizer: DomSanitizer) {
    this.transition = 'bottomIn';
    this._position = 'bottom';
    this.cookieLawClass = true;
  }

  public ngOnInit(): void {
    this.noopener = this._target === '_blank';
    this.transition = this.position === 'bottom' ? 'bottomIn' : 'topIn';

    if (this._awsomeCloseIcon) {
      this.closeSvg = this.domSanitizer.bypassSecurityTrustHtml(
        `<i class="fab ${this._awsomeCloseIcon}"></i>`
      );
    } else {
      this.closeSvg = this.domSanitizer.bypassSecurityTrustHtml(closeIcon);
    }

    this.currentStyles = {
      top: this.position === 'top' ? '0' : null,
      bottom: this.position === 'top' ? 'initial' : null
    };
  }

  public afterDismissAnimation(evt: AnimationEvent): void {
    if (evt.toState === 'topOut' || evt.toState === 'bottomOut') {
      this.isSeen.emit(true);
    }
  }

  public dismiss(evt?: MouseEvent): void {
    if (evt) {
      evt.preventDefault();
    }

    this.transition = this.position === 'top' ? 'topOut' : 'bottomOut';
  }
}
